<template>
  <div class="">
    <empty-file-box limit="10" range-size="1" :show-letter="true" :show-btn="true" :show-full="false"/>
  </div>
</template>

<script>
import EmptyFileBox from "./EmptyFileBox.vue";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: "EmptyFile",
  components: {EmptyFileBox},
  mounted() {
    changePageTitle("Cần bạn trợ giúp: Mất file nhạc");
  }
}
</script>
