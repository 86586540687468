<template>
  <div class="card p-3">
    <div class="flex justify-content-between align-items-center">
      <router-link :to="{name: 'EmptyFile'}">
        <h2 class="text-xl font-semibold underline">Bị mất dữ liệu</h2>
      </router-link>
      <div>
        <button v-on:click="reRender(true)" class="p-3"><i class="uil uil-refresh"></i></button>
        <select class="w-auto px-2 float-right" v-model="sort">
          <option value="title:ASC">Tên bài</option>
          <option value="views:DESC">Lượt nghe</option>
          <option value="created_at:DESC">Thời gian</option>
        </select>
      </div>
    </div>
    <div class="flex justify-between flex-col-reverse lg:flex-row">
      <nav class="responsive-nav is_ligh -mb-0.5 border-transparent">
        <ul uk-switcher="connect: #emptyFileSongs-tab; animation: uk-animation-fade">
          <li><a href="#">Tân nhạc <span>{{ thousandFormat(emptyFileSongs.paginatorInfo.total) }}</span></a></li>
          <li><a href="#">Dân ca <span>{{ thousandFormat(emptyFileFolks.paginatorInfo.total) }}</span></a></li>
          <li><a href="#">Khí nhạc <span>{{ thousandFormat(emptyFileInstrumentals.paginatorInfo.total) }}</span></a>
          </li>
        </ul>
      </nav>
    </div>

    <div class="flex justify-content-end mt-2" v-if="showLetter === true">
      <ul class="flex">
        <li><a class="mr-1" v-bind:class="{'font-bold': !letter}" href="javascript:void(0)" v-on:click="searchByLetter('')">Tất cả</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'A'}" href="javascript:void(0)" v-on:click="searchByLetter('A')">A</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'B'}" href="javascript:void(0)" v-on:click="searchByLetter('B')">B</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'C'}" href="javascript:void(0)" v-on:click="searchByLetter('C')">C</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'D'}" href="javascript:void(0)" v-on:click="searchByLetter('D')">D</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'Đ'}" href="javascript:void(0)" v-on:click="searchByLetter('Đ')">Đ</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'E'}" href="javascript:void(0)" v-on:click="searchByLetter('E')">E</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'G'}" href="javascript:void(0)" v-on:click="searchByLetter('G')">G</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'H'}" href="javascript:void(0)" v-on:click="searchByLetter('H')">H</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'I'}" href="javascript:void(0)" v-on:click="searchByLetter('I')">I</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'K'}" href="javascript:void(0)" v-on:click="searchByLetter('K')">K</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'L'}" href="javascript:void(0)" v-on:click="searchByLetter('L')">L</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'M'}" href="javascript:void(0)" v-on:click="searchByLetter('M')">M</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'N'}" href="javascript:void(0)" v-on:click="searchByLetter('N')">N</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'O'}" href="javascript:void(0)" v-on:click="searchByLetter('O')">O</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'P'}" href="javascript:void(0)" v-on:click="searchByLetter('P')">P</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'Q'}" href="javascript:void(0)" v-on:click="searchByLetter('Q')">Q</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'R'}" href="javascript:void(0)" v-on:click="searchByLetter('R')">R</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'S'}" href="javascript:void(0)" v-on:click="searchByLetter('S')">S</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'T'}" href="javascript:void(0)" v-on:click="searchByLetter('T')">T</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'U'}" href="javascript:void(0)" v-on:click="searchByLetter('U')">U</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'V'}" href="javascript:void(0)" v-on:click="searchByLetter('V')">V</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'X'}" href="javascript:void(0)" v-on:click="searchByLetter('X')">X</a></li>
        <li><a class="mr-1" v-bind:class="{'font-bold': letter === 'Y'}" href="javascript:void(0)" v-on:click="searchByLetter('Y')">Y</a></li>
      </ul>
    </div>

    <div class="uk-switcher" id="emptyFileSongs-tab">
      <div>
        <div class="mt-4" v-if="emptyFileSongs.data.length">
          <song-item-upload :warning="true" :showBtn="showBtn" :isLoggedIn="isLoggedIn" v-for="song in emptyFileSongs.data" :song="song" prefix="bai-hat"
                            :key="'song' + song.id"/>
        </div>
        <div class="mt-4 flex align-items-center" v-bind:class="{'justify-content-between': showFull === true, 'justify-content-end': showFull === false}">
          <div class="flex align-items-center">
            Trang <input class="form-control" style="max-width: 150px;" type="number" v-on:change="loadEmptyFileSongs" v-model="emptyFileSongs.paginatorInfo.currentPage"/>
          </div>
          <v-pagination
              class="justify-content-end"
              v-if="emptyFileSongs.paginatorInfo.lastPage > 1"
              v-model="emptyFileSongs.paginatorInfo.currentPage"
              :pages="emptyFileSongs.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyFileSongs"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="emptyFileFolks.data.length">
          <song-item-upload :warning="true" :showBtn="showBtn" :isLoggedIn="isLoggedIn" v-for="song in emptyFileFolks.data" :song="song" prefix="dan-ca"
                            :key="'folk' + song.id"/>
        </div>
        <div class="mt-4 flex align-items-center" v-bind:class="{'justify-content-between': showFull === true, 'justify-content-end': showFull === false}">
          <div class="flex align-items-center">
            Trang <input class="form-control" style="max-width: 150px;" type="number" v-on:change="loadEmptyFileFolks" v-model="emptyFileFolks.paginatorInfo.currentPage"/>
          </div>
          <v-pagination
              class="justify-content-end"
              v-if="emptyFileFolks.paginatorInfo.lastPage > 1"
              v-model="emptyFileFolks.paginatorInfo.currentPage"
              :pages="emptyFileFolks.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyFileFolks"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="emptyFileInstrumentals.data.length">
          <song-item-upload :warning="true" :showBtn="showBtn" :isLoggedIn="isLoggedIn" v-for="song in emptyFileInstrumentals.data" :song="song" prefix="khi-nhac"
                            :key="'instrumental' + song.id"/>
        </div>
        <div class="mt-4 flex align-items-center" v-bind:class="{'justify-content-between': showFull === true, 'justify-content-end': showFull === false}">
          <div class="flex align-items-center">
            Trang <input class="form-control" style="max-width: 150px;" type="number" v-on:change="loadEmptyFileInstrumentals" v-model="emptyFileInstrumentals.paginatorInfo.currentPage"/>
          </div>
          <v-pagination
              class="justify-content-end"
              v-if="emptyFileInstrumentals.paginatorInfo.lastPage > 1"
              v-model="emptyFileInstrumentals.paginatorInfo.currentPage"
              :pages="emptyFileInstrumentals.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyFileInstrumentals"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SongItemUpload from "../song/SongItemUpload";
import ApiService from "../../core/services/api.service";
import {getUser} from "../../core/services/jwt.service";
import {
  thousandFormat
} from "../../core/services/utils.service";

export default {
  name: "EmptyFileBox",
  components: {SongItemUpload},
  props: {
    limit: Number,
    showLetter: Boolean,
    showBtn: Boolean,
    showFull: Boolean,
    rangeSize: String
  },
  data() {
    return {
      emptyFileSongs: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      emptyFileFolks: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      emptyFileInstrumentals: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      emptyFileKaraokes: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      emptyFilePoems: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      sort: "title:ASC",
      sortColumn: "title",
      sortDirection: "ASC",
      letter: "",
    }
  },
  methods: {
    searchByLetter(letter) {
      this.letter = letter;
    },
    getOrderBy() {
      return [{column: this.sortColumn, order: this.sortDirection}];
    },
    getWhere() {
      let where = {AND: [{column: "empty_file", value: 1}]};
      if (this.letter) {
        where.AND.push({column: "title", operator: "LIKE", value: this.letter + '%'});
      }

      return where;
    },
    loadEmptyFileSongs(refresh) {
      let orderBy = this.getOrderBy();
      let where = this.getWhere();
      let query = `query($limit: Int, $page: Int, $orderBy: [OrderByClause!], $where: WhereConditions) {
        songs(first: $limit, page: $page, orderBy: $orderBy, where: $where) {
          data {
            id
            slug
            title
            subtitle
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 10) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {limit: parseInt(this.limit), page: this.emptyFileSongs.paginatorInfo.currentPage, orderBy: orderBy, where: where})
          .then(({data}) => {
            if (data.data && data.data.songs) {
              this.emptyFileSongs = data.data.songs;
              if (refresh === true) {
                this.$toast.success("Làm mới thành công");
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadEmptyFileFolks() {
      let orderBy = this.getOrderBy();
      let where = this.getWhere();

      let query = `query($limit: Int, $page: Int, $orderBy: [OrderByClause!], $where: WhereConditions) {
        folks(first: $limit, page: $page, orderBy: $orderBy, where: $where) {
          data {
            id
            slug
            title
            subtitle
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 10) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {limit: parseInt(this.limit), page: this.emptyFileFolks.paginatorInfo.currentPage, orderBy: orderBy, where: where})
          .then(({data}) => {
            if (data.data && data.data.folks) {
              this.emptyFileFolks = data.data.folks;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadEmptyFileInstrumentals() {
      let orderBy = this.getOrderBy();
      let where = this.getWhere();

      let query = `query($limit: Int, $page: Int, $orderBy: [OrderByClause!], $where: WhereConditions) {
        instrumentals(first: $limit, page: $page, orderBy: $orderBy, where: $where) {
          data {
            id
            slug
            title
            subtitle
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 10) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {limit: parseInt(this.limit), page: this.emptyFileInstrumentals.paginatorInfo.currentPage, orderBy: orderBy, where: where})
          .then(({data}) => {
            if (data.data && data.data.instrumentals) {
              this.emptyFileInstrumentals = data.data.instrumentals;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadEmptyFileKaraokes() {
      let orderBy = this.getOrderBy();
      let where = this.getWhere();

      let query = `query($limit: Int, $page: Int, $orderBy: [OrderByClause!], $where: WhereConditions) {
        karaokes(first: $limit, page: $page, orderBy: $orderBy, where: $where) {
          data {
            id
            slug
            title
            subtitle
            views
            created_at
            thumbnail {
              url
            }
            users(first: 10) {
              data {
                id
                username
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {limit: parseInt(this.limit), page: this.emptyFileKaraokes.paginatorInfo.currentPage, orderBy: orderBy, where: where})
          .then(({data}) => {
            if (data.data && data.data.karaokes) {
              this.emptyFileKaraokes = data.data.karaokes;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadEmptyFilePoems() {
      let orderBy = this.getOrderBy();
      let where = this.getWhere();

      let query = `query($limit: Int, $page: Int, $orderBy: [OrderByClause!], $where: WhereConditions) {
        poems(first: $limit, page: $page, orderBy: $orderBy, where: $where) {
          data {
            id
            slug
            title
            subtitle
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 10) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {limit: parseInt(this.limit), page: this.emptyFilePoems.paginatorInfo.currentPage, orderBy: orderBy, where: where})
          .then(({data}) => {
            if (data.data && data.data.poems) {
              this.emptyFilePoems = data.data.poems;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
    reRender(refresh = false) {
      this.loadEmptyFileSongs(refresh);
      this.loadEmptyFileFolks();
      this.loadEmptyFileInstrumentals();
      this.loadEmptyFileKaraokes();
      this.loadEmptyFilePoems();
    }
  },
  mounted() {
    this.reRender();
  },
  watch: {
    sort: {
      handler(sort) {
        let tmp = sort.split(":");
        this.sortColumn = tmp[0];
        this.sortDirection = tmp[1];
        this.emptyFileSongs.paginatorInfo.currentPage = 1;
        this.emptyFileFolks.paginatorInfo.currentPage = 1;
        this.emptyFileInstrumentals.paginatorInfo.currentPage = 1;
        this.emptyFileKaraokes.paginatorInfo.currentPage = 1;
        this.emptyFilePoems.paginatorInfo.currentPage = 1;
        this.reRender();
      }
    },
    letter: {
      handler() {
        this.emptyFileSongs.paginatorInfo.currentPage = 1;
        this.emptyFileFolks.paginatorInfo.currentPage = 1;
        this.emptyFileInstrumentals.paginatorInfo.currentPage = 1;
        this.emptyFileKaraokes.paginatorInfo.currentPage = 1;
        this.emptyFilePoems.paginatorInfo.currentPage = 1;
        this.reRender();
      }
    }
  },
  computed: {
    isLoggedIn() {
      let user = getUser();
      return user ? true : false;
    }
  }
}
</script>
